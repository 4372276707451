<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.6397 11.3496C4.56137 12.4279 3.95557 13.8905 3.95557 15.4155C3.95557 16.9405 4.56137 18.403 5.6397 19.4813C6.71804 20.5597 8.18057 21.1655 9.70557 21.1655C10.5028 21.1655 11.2829 20.9999 12 20.6878V18.3816C11.3466 18.887 10.5405 19.1655 9.70557 19.1655C8.711 19.1655 7.75718 18.7704 7.05392 18.0671C6.35065 17.3638 5.95557 16.41 5.95557 15.4155C5.95557 14.4209 6.35065 13.4671 7.05392 12.7638L14.4785 5.33918C14.6875 5.13025 14.9355 4.96452 15.2085 4.85145C15.4815 4.73837 15.7741 4.68017 16.0695 4.68018C16.365 4.68018 16.6576 4.73837 16.9306 4.85145C17.2035 4.96452 17.4516 5.13025 17.6605 5.33918C17.8694 5.54812 18.0352 5.79616 18.1483 6.06914C18.2613 6.34212 18.3195 6.6347 18.3195 6.93017C18.3195 7.22565 18.2613 7.51823 18.1483 7.79121C18.0352 8.06419 17.8694 8.31223 17.6605 8.52116L11.6501 14.5316C11.5095 14.6722 11.3187 14.7512 11.1198 14.7512C10.9209 14.7512 10.7301 14.6722 10.5894 14.5316C10.4488 14.3909 10.3698 14.2002 10.3698 14.0012C10.3698 13.8023 10.4488 13.6116 10.5894 13.4709L16.0695 7.99083L14.6553 6.57662L9.17524 12.0567C8.65951 12.5724 8.36978 13.2719 8.36978 14.0012C8.36978 14.7306 8.65951 15.4301 9.17524 15.9458C9.69096 16.4615 10.3904 16.7512 11.1198 16.7512C11.6905 16.7512 12.2429 16.5738 12.7034 16.2495C12.7862 16.1452 12.8727 16.0488 12.9608 15.9607C13.0347 15.8868 13.1146 15.8139 13.2 15.7434V15.4999C13.2 14.1015 13.9002 12.9549 14.7734 12.2064C15.6398 11.4638 16.7955 10.9999 18 10.9999L18.0102 10.9999L19.0747 9.93538C19.8718 9.13835 20.3195 8.05734 20.3195 6.93017C20.3195 5.80301 19.8718 4.722 19.0747 3.92497C18.2777 3.12794 17.1967 2.68018 16.0695 2.68018C14.9424 2.68018 13.8614 3.12794 13.0643 3.92497L5.6397 11.3496ZM22 21.8V18.3C22 17.6 21.4 17 20.8 17V15.5C20.8 14.1 19.4 13 18 13C16.6 13 15.2 14.1 15.2 15.5V17C14.6 17 14 17.6 14 18.2V21.7C14 22.4 14.6 23 15.2 23H20.7C21.4 23 22 22.4 22 21.8ZM18 14.2002C18.8 14.2002 19.5 14.7002 19.5 15.5002V17.0002H16.5V15.5002C16.5 14.7002 17.2 14.2002 18 14.2002Z"
                :fill="color"
            />
            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>
