<script
    lang="ts"
    setup
>
    import { ChatMessageKindEnum } from '~/ts/enums/chat'

    type Props = {
        modelValue: ChatMessageKindEnum
        disabled: boolean
    }

    type Emit = {
        (event: 'update:modelValue', value: any): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const inputModel = useSyncProp(props, 'modelValue', emit)

    const tabs = Object.values(ChatMessageKindEnum)
</script>

<template>
    <div class="relative flex gap-2">
        <AppButton
            v-for="tab in tabs"
            :key="tab"
            wrapper
            :class="[
                'group flex flex-col items-center',
                { 'opacity-50 pointer-events-none': props.disabled },
            ]"
            :disabled="props.disabled"
            @click="!props.disabled && (inputModel = tab)"
        >
            <div
                :class="[
                    `
                        mb-1.5
                        px-1
                        text-[14px]
                        font-medium
                        leading-[130%]
                        text-[#8a8f9e]
                        transition-[color_var(--transition-default-duration-with-ease)]
                    `,
                    { 'transition-none text-black': inputModel === tab },
                ]"
            >
                {{ $t(tab) }}
            </div>

            <div
                :class="[
                    `
                        w-full
                        h-0.5
                        rounded-[2px]
                        bg-transparent
                        transition-[background-color_var(--transition-default-duration-with-ease)]
                        group-hover:bg-[rgba(0,0,0,0.12)]
                        group-hover:transition-none
                    `,
                    { '!bg-black': inputModel === tab },
                ]"
            ></div>
        </AppButton>
    </div>
</template>
