<script
    lang="ts"
    setup
>
    import { useChatStore } from '~/stores/chat'

    const chatStore = useChatStore()

    const currentChat = chatStore.currentChat

    const scrollToMessage = (): void => {
        useEventBus().emit(BUS_EVENT_CHAT_SCROLL_BODY_TO_MESSAGE, currentChat.editableMessage._id)
    }

    const cancelEditing = (): void => {
        currentChat.editableMessage = undefined
    }
</script>

<template>
    <AppTransitionCollapse>
        <div
            v-if="currentChat.editableMessage"
            key="editable-message"
            class="z-[1] absolute w-full bottom-full left-0 flex"
        >
            <div
                :class="[
                    'overflow-hidden flex items-center w-full m-1 bg-white rounded-lg cursor-pointer',
                    { 'bg-[#fefae8]': currentChat.editableMessage.is_note },
                ]"
                @click="scrollToMessage()"
            >
                <div class="flex items-center justify-center w-13 h-13">
                    <AppIconPencil
                        size="20"
                        color="#000"
                    />
                </div>

                <div class="w-3/4 flex-grow m-1 flex flex-col">
                    <div class="text-sm font-medium text-text/secondary">
                        {{ $t('editing') }}
                    </div>

                    <div class="overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                        {{ currentChat.editableMessage.text }}
                    </div>
                </div>

                <div class="flex items-center justify-center w-13 h-13">
                    <AppButtonIcon
                        small
                        @click.stop="cancelEditing()"
                    >
                        <AppIconClose size="20" />
                    </AppButtonIcon>
                </div>
            </div>
        </div>
    </AppTransitionCollapse>
</template>
