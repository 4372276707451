<script
    lang="ts"
    setup
>
    type Emit = {
        (event: 'select-emoji', value: string): void
    }

    const emit = defineEmits<Emit>()

    const dropdownRef = ref<ReturnType<typeof defineComponent>>()

    const emojiGroups = [
        [ '😇', '😕', '😡', '😈', '😞', '😘', '😋' ],
        [ '😥', '😩', '😁', '😆', '😉', '😎', '😐' ],
        [ '😜', '😩', '😯', '👍', '👎', '😃', '🦄' ],
    ]
</script>

<template>
    <AppDropdown
        ref="dropdownRef"
        from-bottom
        width="auto"
        content-class="
            max-tablet:min-w-[100vw]
            max-tablet:bottom-full
            max-tablet:border-b
            max-tablet:border-b-[#f6f7f8]
            max-tablet:rounded-[16px_16px_0_0]
            max-tablet:shadow-[0_-16px_30px_0_rgba(46,56,66,0.05)]
        "
        class="max-tablet:static"
    >
        <template #activator="{ toggle, active }">
            <slot
                name="activator"
                :active="active"
                :toggle="toggle"
            >
                <AppButtonIcon
                    v-slot="{ color }"
                    small
                    :active="active"
                    class="!mr-2.5"
                    @click="toggle()"
                >
                    <AppIconEmotionHappyOutline
                        size="20"
                        :color="color"
                    />
                </AppButtonIcon>
            </slot>
        </template>

        <template #default="{ close }">
            <div class="flex flex-col items-center p-2">
                <div
                    v-for="(emojis, index) in emojiGroups"
                    :key="index"
                    class="flex"
                >
                    <AppButton
                        v-for="emoji in emojis"
                        :key="emoji"
                        wrapper
                        class="
                            !py-2.5
                            tablet:!py-1
                            !px-3
                            tablet:!px-1.5
                            !text-[24px]
                            !leading-[28px]
                            text-center
                            rounded-[4px]
                            hover:!bg-[#ebeef1]
                            group
                        "
                        @click="emit('select-emoji', emoji); close()"
                    >
                        <div
                            class="
                                group-active:scale-90
                                transition-[transform_var(--transition-default-duration-with-ease)]
                            "
                        >
                            {{ emoji }}
                        </div>
                    </AppButton>
                </div>
            </div>
        </template>
    </AppDropdown>
</template>
