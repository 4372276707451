<script
    lang="ts"
    setup
>
    import type { AppInfoSectionItem } from '~/ts/types/app'
    import type { Visitor } from '~/ts/types/visitor'
    import { useChatStore } from '~/stores/chat'
    import { useUserStore } from '~/stores/user'
    import getOperatorName from '~/helpers/getOperatorName'

    const chatStore = useChatStore()
    const userStore = useUserStore()

    const currentChat = chatStore.currentChat

    const sectionDefaults = useAppCookie('chat:info-sections', {
        default: () => ({
            chatDetails: true,
            clientDetails: true,
            deviceDetails: true,
        }),
    })

    const { t } = useLang()

    const deviceGeo = computed<Visitor['device']['parse']['geo'] | undefined>(() => {
        return currentChat.visitor.device?.parse?.geo
    })

    const deviceUa = computed<Visitor['device']['parse']['ua'] | undefined>(() => {
        return currentChat.visitor.device?.parse?.ua
    })

    const deviceDetails = computed<AppInfoSectionItem['values']>(() => {
        const device = currentChat.visitor.device

        if (!device) {
            return []
        }

        return [
            {
                key: 'country',
                label: t('chat-device-info-country'),
                value: (() => {
                    if (!deviceGeo.value?.country) {
                        return
                    }

                    return {
                        name: deviceGeo.value?.country + (deviceGeo.value?.city ? `, ${ deviceGeo.value.city }` : ''),
                        iso: deviceGeo.value.isoCode,
                    }
                })(),
            },
            {
                key: 'timezone',
                label: t('chat-device-info-timezone'),
                value: deviceGeo.value?.timezone,
            },
            {
                key: 'referrer',
                label: t('chat-device-info-referrer'),
                value: device.referrer,
            },
            {
                key: 'ip',
                label: t('chat-device-info-ip'),
                value: device.ip,
            },
            {
                key: 'browser',
                label: t('chat-device-info-browser'),
                value: (() => {
                    if (!deviceUa.value) {
                        return
                    }

                    return `${ deviceUa.value.browser } ${ deviceUa.value.browserVersion }`
                })(),
            },
            {
                key: 'platform',
                label: t('chat-device-info-platform'),
                value: deviceUa.value?.platform,
            },
            {
                key: 'user-agent',
                label: t('chat-device-info-user-agent'),
                value: device.user_agent,
            },
            {
                key: 'created-at',
                label: t('chat-device-info-created-at'),
                value: device.created_at && dateUtil.fromSQL(device.created_at).toFormat(DATE_DISPLAY_DATE_FORMAT),
            },
            {
                key: 'visited-at',
                label: t('chat-device-info-visited-at'),
                value: device.visited_at && dateUtil.fromSQL(device.visited_at).toFormat(DATE_DISPLAY_DATE_FORMAT),
            },
        ].filter(v => v.value)
    })

    const items = computed<AppInfoSectionItem[]>(() => [
        {
            name: 'chat-details',
            title: t('chat-details'),
            values: [
                { label: t('operator'), value: getOperatorName(currentChat.operator) },
                { label: t('status'), value: t('chat-status-' + currentChat.status) },
                { label: t('communication-channel'), value: t('chat-channel-' + currentChat.channel_type) },
            ],
            defaultValue: sectionDefaults.value.chatDetails,
            onToggled: value => (sectionDefaults.value = { ...sectionDefaults.value, chatDetails: value }),
        },
        {
            name: 'client-details',
            title: t('client-details'),
            values: [ /* Заповнюється окремо */ ],
            defaultValue: sectionDefaults.value.clientDetails,
            onToggled: value => (sectionDefaults.value = { ...sectionDefaults.value, clientDetails: value }),
        },
        ...(
            deviceDetails.value.length
                ? [ {
                    name: 'device-details',
                    title: t('device-details'),
                    values: deviceDetails.value,
                    defaultValue: sectionDefaults.value.deviceDetails,
                    onToggled: value => (sectionDefaults.value = { ...sectionDefaults.value, deviceDetails: value }),
                } ]
                : []
        ),
    ])
</script>

<template>
    <AppInfoSection
        :items="items"
        item-class="!my-2 !mx-5"
        class="!flex-none"
    >
        <template #item-body:client-details>
            <ChatConversationSidebarInfoClientDetailsForm />
        </template>

        <template #item-body:device-details:value-text:country="{ value: { value } }">
            <ChatConversationSidebarInfoDeviceDetailsCountry
                :name="value.name"
                :iso="value.iso"
            />
        </template>

        <template #item-body:device-details:value-text:timezone="{ value: { value } }">
            <ChatConversationSidebarInfoDeviceDetailsTime :value="value" />
        </template>

        <template #item-body:device-details:value-text:platform="{ value: { value } }">
            <ChatConversationSidebarInfoDeviceDetailsPlatform :value="value" />
        </template>

        <template
            v-if="!userStore.activeSubscription"
            #item-before-body:device-details
        >
            <AppProRestrictionBlock
                class="
                    z-[1]
                    absolute
                    top-0
                    left-0
                    h-full
                    w-full
                "
            >
                <template #hint>
                    {{ $t('available-in-pro-device-details-hint') }}
                </template>
            </AppProRestrictionBlock>
        </template>
    </AppInfoSection>
</template>
