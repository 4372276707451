<script
    lang="ts"
    setup
>
    import { useChatStore } from '~/stores/chat'

    const chatStore = useChatStore()

    const currentChat = chatStore.currentChat

    const style = useCssModule()

    const containerClass = computed<string[]>(() => {
        const classes = [ style['chat-conversation-body__scroll-down'] ]

        if (currentChat.editableMessage) {
            classes.push(style['chat-conversation-body__scroll-down--with-indent'])
        }

        return classes
    })
</script>

<template>
    <AppButton
        secondary
        :class="containerClass"
    >
        <AppIconChevronDown
            size="20"
            color="#000"
        />
    </AppButton>
</template>

<style
    lang="sass"
    module
    scoped
>
    .chat-conversation-body__scroll-down
        z-index: 1
        position: absolute
        top: var(--top, -56px)
        right: 16px
        width: 40px
        height: 40px
        padding: 0 !important
        border-radius: 50% !important
        transition: top var(--transition-chat-footer-outset-el-duration) var(--transition-default-ease), background-color var(--transition-default-duration-with-ease) !important
        will-change: top, background-color

        &--with-indent
            --top: -100px
</style>
