<script
    lang="ts"
    setup
>
    import type { AnyFn } from '~/ts/types/common'
    import type { FormSubmitContext } from '~/ts/types/form'
    import { useChatStore } from '~/stores/chat'
    import handleFormError from '~/helpers/handleFormError'
    import rules from '~/helpers/formValidationRules'

    type FormRules = {
        operator: AnyFn
    }

    type FormValues = {
        operator: number
    }

    const searchRef = ref<ReturnType<typeof defineComponent>>()

    const chatStore = useChatStore()

    const currentChat = chatStore.currentChat

    const formRules: FormRules = {
        operator: value => rules.ruleChain(
            () => rules.required(value),
            () => rules.oneOf(value, chatStore.operatorOptions.map(v => v.value)),
        ),
    }

    const formValues = ref<FormValues>({
        operator: null,
    })

    const { tablet } = useWindowSize()

    onStartTyping(() => {
        if (searchRef.value && !searchRef.value.$refs.inputRef.disabled) {
            searchRef.value.$refs.inputRef.focus()
        }
    })

    const search = ref<string>('')
    const showModal = ref<boolean>(false)
    const pending = ref<boolean>(false)

    const disabled = computed<boolean>(() => {
        return chatStore.isCurrentChatNew
            || chatStore.isCurrentChatClosed
            || chatStore.isCurrentChatInTransferProcess
    })

    const filteredOperatorOptions = computed(() => {
        if (!search.value) {
            return chatStore.operatorOptions
        }

        const items = []

        for (const index in chatStore.operatorOptions) {
            const item = chatStore.operatorOptions[index]

            if (String(item._extra.searchValue).toLowerCase().includes(search.value.toLowerCase())) {
                items.push(item)
            }
        }

        return items
    })

    const submit = async ({ operator }: FormValues, { setFieldError }: FormSubmitContext): Promise<void> => {
        if (pending.value) {
            return
        }

        pending.value = true

        try {
            const data = await useApi().chat.chatTransfer({
                id: currentChat.id,
                data: {
                    operator_id: operator,
                },
            })

            closeModal()

            chatStore.updateChat(currentChat.id, data, true)

            await nextTick(() => chatStore.handleTransferredChats())
        } catch (error) {
            handleFormError({ error, setFieldError })
        } finally {
            pending.value = false
        }
    }

    const openModal = async (): Promise<void> => {
        formValues.value.operator = filteredOperatorOptions.value[0]?.value

        showModal.value = true

        await nextTick()

        // tablet.value - бо на мобільному екрані це ламає анімацію
        if (searchRef.value && tablet.value) {
            searchRef.value.$refs.inputRef.focus()
        }
    }

    const closeModal = () => {
        showModal.value = false

        search.value = ''

        formValues.value.operator = null
    }
</script>

<template>
    <slot
        name="activator"
        :open="openModal"
        :disabled="disabled"
    />

    <AppTransitionSlideXMobileOnly>
        <AppModalOrSheetIfMobile
            v-if="showModal"
            key="modal"
            content-class="tablet:!max-w-[460px]"
            @close="closeModal()"
        >
            <template #icon>
                <AppIconUserSwitch size="34" />
            </template>

            <template #title>
                {{ $t('transfer-to-operator') }}
            </template>

            <template #header-right>
                <AppButton
                    small
                    :loading="pending"
                    @click="$refs.form.triggerSubmit()"
                >
                    {{ $t('transfer') }}
                </AppButton>
            </template>

            <AppForm
                ref="form"
                v-slot="{ errors }"
                :rules="formRules"
                :values="formValues"
                class="max-tablet:h-full"
                @submit="submit"
            >
                <AppFormFieldSearch
                    ref="searchRef"
                    v-model.trim="search"
                    name="search"
                    :placeholder="$t('search')"
                    class="!w-auto !mb-1"
                />

                <div class="overflow-y-auto tablet:h-[254px]">
                    <AppFormFieldRadio
                        v-for="option in filteredOperatorOptions"
                        :key="option.value"
                        v-model="formValues.operator"
                        v-model:error="errors.operator"
                        :class="[
                            'flex items-center min-h-[72px] mb-1 pl-4 rounded-[8px] hover:bg-[#f6f7f8]',
                            { 'bg-[#f6f7f8]': formValues.operator === option.value },
                        ]"
                        :value="option.value"
                        :label="option.text"
                        name="operator"
                        label-class="!flex-1"
                    >
                        <template #label-text>
                            <span class="flex w-full text-[14px]">
                                <span class="mr-4">
                                    <AppAvatar
                                        :src="option._extra.photo?.thumbnails?.v60x60"
                                        size="40"
                                    >
                                        {{ option._extra.name?.[0] || option._extra.email[0] }}
                                    </AppAvatar>
                                </span>

                                <span class="flex flex-col justify-center leading-[130%]">
                                    <span class="mb-1 font-medium">{{ option._extra.name }}</span>

                                    <span class="text-[#8a8f9e] font-normal">{{ option._extra.email }}</span>
                                </span>
                            </span>
                        </template>
                    </AppFormFieldRadio>
                </div>

                <AppHint class="!m-[16px_0] max-tablet:!mt-auto">
                    {{ $t('chat-sidebar-transfer-conversation-hint') }}
                </AppHint>

                <AppMobileOnly opposite>
                    <AppFormActions class="!mt-2">
                        <AppButton
                            secondary
                            :disabled="pending"
                            @click="closeModal()"
                        >
                            {{ $t('cancel') }}
                        </AppButton>

                        <AppButton
                            type="submit"
                            :loading="pending"
                        >
                            {{ $t('transfer') }}
                        </AppButton>
                    </AppFormActions>
                </AppMobileOnly>
            </AppForm>
        </AppModalOrSheetIfMobile>
    </AppTransitionSlideXMobileOnly>
</template>
