<script
    lang="ts"
    setup
>
    type Props = {
        url: string
    }

    const props = defineProps<Props>()

    const href = computed<string>(() => stringUtil.ensureProtocol(props.url))

    // TODO реалізувати або видалити
    const openConfirm = async () => {
        if (!await useConfirm({
            titleText: 'Вкурсі шо зараз підеш туди куда лінка веде?',
            bodyText: 'це проста зробив тому шо можу. на ізі пізі рілі',
            cancelText: 'ніт, залишусь тута',
            confirmText: 'пагналі',
        })) {
            return
        }

        window.open(href.value, '_blank')
    }
</script>

<template>
    <a
        class="text-[16px] leading-[130%] text-[#0d54c9] underline"
        :href="href"
        :title="href"
        target="_blank"
        rel="noopener noreferrer"
        @click.prevent="openConfirm"
    >
        {{ props.url }}
    </a>
</template>
