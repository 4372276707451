<script
    lang="ts"
    setup
>
    import { ChatKindEnum } from '~/ts/enums/chat'
    import { useUserStore } from '~/stores/user'
    import { useChatStore } from '~/stores/chat'
    import getOperatorName from '~/helpers/getOperatorName'

    const userStore = useUserStore()
    const chatStore = useChatStore()

    const currentChat = chatStore.currentChat

    const pendingFor = ref<'accept' | 'reject' | 'restore' | 'transfer' | undefined>()
    const additionalButtonText = ref<string>('')

    const isItMeTransferCurrentChat = computed<boolean>(() => {
        if (!currentChat.chatTransfer) {
            return false
        }

        return userStore.currentOperator.id === currentChat.chatTransfer.fromOperator.id
    })

    const { t } = useLang()

    const hintText = computed<string>(() => {
        if (chatStore.isCurrentChatNew) {
            return t('chat-conversation-confirm-accept-hint')
        }

        if (chatStore.isCurrentChatClosed) {
            return t('chat-conversation-confirm-restore-hint')
        }

        if (chatStore.isCurrentChatInTransferProcess) {
            if (isItMeTransferCurrentChat.value) {
                return t(
                    'chat-conversation-confirm-reject-transfer-hint',
                    getOperatorName(currentChat.chatTransfer.toOperator),
                )
            } else {
                return t(
                    'chat-conversation-confirm-accept-transfer-hint',
                    getOperatorName(currentChat.chatTransfer.fromOperator),
                )
            }
        }

        return 'Unknown action'
    })

    const acceptChat = async (): Promise<void> => {
        if (pendingFor.value) {
            return
        }

        pendingFor.value = 'accept'

        try {
            const data = await useApi().chat.chatAccept({
                id: currentChat.id,
                data: {
                    operator_id: userStore.currentOperator.id,
                },
            })

            // TODO обробка ситуації, якщо чат вже встиг прийняти інший оператор

            if (chatStore.isCurrentChatNew) {
                chatStore.insertMessagesIntoChat(currentChat, data.messages, { position: 'after' })
            }

            chatStore.updateChat(currentChat.id, data, true)

            if (currentChat.chatTransfer) {
                currentChat.chatTransfer = undefined
            } else {
                await navigateTo({
                    name: 'p-pid-chat-kind-cid',
                    params: {
                        kind: ChatKindEnum.My,
                        cid: data.id,
                    },
                })

                chatStore.cidPageRefreshKey++
            }

            useNotify().push({
                type: 'success',
                text: useLang().t('successfully-accepted'),
            })
        } finally {
            pendingFor.value = undefined
        }
    }

    const rejectChat = async (): Promise<void> => {
        if (pendingFor.value || (!currentChat.chatTransfer && !await useConfirm())) {
            return
        }

        pendingFor.value = 'reject'

        try {
            const data = await useApi().chat.chatReject({
                id: currentChat.id,
                data: {
                    operator_id: userStore.currentOperator.id,
                },
            })

            const isCurrentChatNew = chatStore.isCurrentChatNew
            const isItMeTransferCurrentChatValue = isItMeTransferCurrentChat.value

            if (isCurrentChatNew || isItMeTransferCurrentChatValue) {
                if (isCurrentChatNew) {
                    chatStore.insertMessagesIntoChat(currentChat, data.messages, { position: 'after' })
                }

                chatStore.updateChat(currentChat.id, data, true)

                if (isCurrentChatNew) {
                    await chatStore.removeCurrentCid()
                    await chatStore.ensureCurrentCid(ChatKindEnum.New)
                }

                // Ре-рендеринг для активації початкових залежностей в чаті (читання повідомлень тощо)
                if (isItMeTransferCurrentChatValue) {
                    chatStore.cidPageRefreshKey++
                }
            } else {
                await chatStore.removeChats([ currentChat ])
            }

            useNotify().push({
                type: 'success',
                text: useLang().t('successfully-rejected'),
            })
        } finally {
            pendingFor.value = undefined
        }
    }

    const restoreChat = async (): Promise<void> => {
        if (pendingFor.value || !await useConfirm()) {
            return
        }

        pendingFor.value = 'restore'

        try {
            const data = await useApi().chat.chatRestore({
                id: currentChat.id,
                data: {
                    operator_id: userStore.currentOperator.id,
                },
            })

            chatStore.insertMessagesIntoChat(currentChat, data.messages, { position: 'after' })
            chatStore.updateChat(currentChat.id, data, true)

            navigateTo({
                name: 'p-pid-chat-kind-cid',
                params: {
                    kind: ChatKindEnum.My,
                    cid: data.id,
                },
            })

            useNotify().push({
                type: 'success',
                text: useLang().t('successfully-restored'),
            })
        } finally {
            pendingFor.value = undefined
        }
    }

    const runTransferTimer = (): void => {
        const postfix = t('seconds-short')

        let seconds = Math.floor(
            dateUtil
                .rawFromSQL(currentChat.chatTransfer.expired_at, { zone: 'utc' })
                .diffNow('second')
                .seconds,
        )

        if (seconds < 1) {
            return
        }

        const timer = useTimer(
            () => {
                seconds--

                additionalButtonText.value = ' ' + seconds + postfix

                if (!seconds) {
                    timer.stop()
                }
            },
            1000,
            {
                interval: true,
                immediate: true,
                callAtStart: true,
            },
        )
    }

    onMounted(() => {
        if (chatStore.isCurrentChatInTransferProcess) {
            runTransferTimer()
        }
    })
</script>

<template>
    <div class="flex flex-col items-center justify-center w-full h-full px-4 tablet:px-[100px] text-center">
        <AppHint class="max-w-[465px]">
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="hintText"></span>
            <!-- eslint-enable vue/no-v-html -->
        </AppHint>

        <div
            v-if="chatStore.isCurrentChatNew || chatStore.isCurrentChatInTransferProcess"
            key="accept"
            class="flex items-center mt-4"
        >
            <AppButton
                key="reject"
                secondary
                :loading="pendingFor === 'reject'"
                class="!mr-4"
                @click="rejectChat()"
            >
                {{ $t('reject') }}{{ isItMeTransferCurrentChat ? additionalButtonText : '' }}
            </AppButton>

            <AppButton
                v-if="!chatStore.isCurrentChatInTransferProcess || !isItMeTransferCurrentChat"
                key="accept"
                :loading="pendingFor === 'accept'"
                @click="acceptChat()"
            >
                {{ $t('accept-chat') }}{{ additionalButtonText }}
            </AppButton>
        </div>

        <div
            v-else-if="chatStore.isCurrentChatClosed"
            key="restore"
            class="flex items-center mt-4"
        >
            <AppButton
                :loading="pendingFor === 'restore'"
                @click="restoreChat()"
            >
                {{ $t('restore-chat') }}
            </AppButton>
        </div>
    </div>
</template>
