<script
    lang="ts"
    setup
>
    import type { AnyFn } from '~/ts/types/common'
    import type { FormContext } from '~/ts/types/form'
    import { VisitorContactEnum } from '~/ts/enums/visitor'
    import { useChatStore } from '~/stores/chat'
    import handleFormError from '~/helpers/handleFormError'
    import rules from '~/helpers/formValidationRules'

    type DynamicFormRules = Record<string, AnyFn>

    type DynamicFormValues = Record<string, string>

    const formRef = ref<ReturnType<typeof defineComponent>>()

    const chatStore = useChatStore()

    const currentChat = chatStore.currentChat

    const dynamicFormRules = ref<DynamicFormRules>({})
    const dynamicFormValues = ref<DynamicFormValues>({})
    const recentlyUpdated = ref<string>('')
    const pendingArray = ref<string[]>([])

    const getDynamicFormValuesByName = (fieldName: 'email' | 'phone') => {
        const values = {}

        for (const name in dynamicFormValues.value) {
            if (name.startsWith(fieldName)) {
                values[name] = dynamicFormValues.value[name]
            }
        }

        return values
    }

    const dynamicFormValuesEmail = computed<DynamicFormValues>(() => getDynamicFormValuesByName('email'))
    const dynamicFormValuesPhone = computed<DynamicFormValues>(() => getDynamicFormValuesByName('phone'))

    const refreshDynamicFormContext = (): void => {
        const fieldValues = {
            newEmail: '',
            newPhone: '',
        }

        const getRequiredRule = (fieldName: 'email' | 'phone') => value => rules.ruleChain(
            () => rules.required(value),
            () => rules[fieldName === 'email' ? 'email' : 'maybePhone'](value),
        )

        // для створених полів
        const fieldNamePrefixesByType = {
            [VisitorContactEnum.Email]: 'email',
            [VisitorContactEnum.Phone]: 'phone',
        }

        const fieldRuleByType = {
            [VisitorContactEnum.Email]: rules.email,
            [VisitorContactEnum.Phone]: rules.maybePhone,
        }

        const fieldRules = {
            newEmail: getRequiredRule('email'),
            newPhone: getRequiredRule('phone'),
        }

        for (const contact of currentChat.visitor.visitorContacts) {
            const fieldName = fieldNamePrefixesByType[contact.type] + contact.id

            fieldValues[fieldName] = contact.value

            fieldRules[fieldName] = fieldRuleByType[contact.type]
        }

        dynamicFormRules.value = fieldRules
        dynamicFormValues.value = fieldValues
    }

    refreshDynamicFormContext()

    const { t } = useLang()

    const getIdFromFieldName = (name: string): number => +stringUtil.getDigits(name)

    const createContact = debounceFn(async (fieldName: 'newEmail' | 'newPhone', formProps: FormContext): Promise<void> => {
        if (!formProps.validateField(fieldName)) {
            return
        }

        if (!pendingArray.value.includes(fieldName)) {
            pendingArray.value.push(fieldName)
        }

        const type = fieldName === 'newEmail' ? VisitorContactEnum.Email : VisitorContactEnum.Phone

        try {
            const data = await useApi().visitor.createContact({
                visitorId: currentChat.visitor.id,
                data: {
                    type,
                    value: formProps.values[fieldName],
                },
            })

            currentChat.visitor.visitorContacts.push(data)

            refreshDynamicFormContext()

            await nextTick()

            // Встановлення фокуса у тільки що створене поле
            if (document.activeElement.tagName === 'INPUT') {
                const formInputs = formRef.value.$el.querySelectorAll('input')

                const fieldIndex = [ ...formInputs ].findIndex(el => el.name === fieldName)

                formInputs[fieldIndex - 1]?.focus()
            }

            useNotify().push({
                type: 'success',
                tag: 'chat-contact',
                text: t('successfully-created'),
            })
        } catch (error) {
            handleFormError({
                error,
                setFieldError: formProps.setFieldError,
            })
        } finally {
            pendingArray.value = pendingArray.value.filter(v => v !== fieldName)
        }
    }, 500)

    const updateContact = debounceFn(async (fieldName: string, formProps: FormContext): Promise<void> => {
        const fieldValue = formProps.values[fieldName]
        const contactId = getIdFromFieldName(fieldName)
        const contactIndex = currentChat.visitor.visitorContacts.findIndex(v => v.id === contactId)

        if (
            !fieldValue
            || !formProps.validateField(fieldName)
            || (currentChat.visitor.visitorContacts[contactIndex].value === formProps.values[fieldName])
        ) {
            return
        }

        if (!pendingArray.value.includes(fieldName)) {
            pendingArray.value.push(fieldName)
        }

        try {
            currentChat.visitor.visitorContacts[contactIndex] = await useApi().visitor.updateContact({
                visitorId: currentChat.visitor.id,
                id: contactId,
                data: { value: fieldValue },
            })

            recentlyUpdated.value = fieldName

            setTimeout(() => {
                recentlyUpdated.value = ''
            }, 500)
        } catch (error) {
            handleFormError({
                error,
                setFieldError: formProps.setFieldError,
            })
        } finally {
            pendingArray.value = pendingArray.value.filter(v => v !== fieldName)
        }
    }, 500)

    const onFieldBlur = (fieldName: string, formProps: FormContext): void => {
        if ([ 'newEmail', 'newPhone' ].includes(fieldName)) {
            if (!dynamicFormValues.value[fieldName]) {
                formProps.setFieldError(fieldName, '')
            }

            return
        }

        if (!pendingArray.value.includes(fieldName)) {
            pendingArray.value.push(fieldName)
        }

        if (!dynamicFormValues.value[fieldName]) {
            deleteContact(getIdFromFieldName(fieldName))
        } else if (formProps.errors[fieldName]) {
            const contactId = getIdFromFieldName(fieldName)
            const contact = currentChat.visitor.visitorContacts.find(v => v.id === contactId)

            dynamicFormValues.value[fieldName] = contact.value

            refreshDynamicFormContext()
        }

        pendingArray.value = pendingArray.value.filter(v => v !== fieldName)
    }

    const deleteContact = async (id: number): Promise<void> => {
        // TODO чи треба тут pending?
        try {
            await useApi().visitor.deleteContact({
                id,
                visitorId: currentChat.visitor.id,
            })

            const contactIndex = currentChat.visitor.visitorContacts.findIndex(v => v.id === id)

            currentChat.visitor.visitorContacts.splice(contactIndex, 1)

            refreshDynamicFormContext()

            useNotify().push({
                type: 'success',
                tag: 'chat-contact',
                text: t('successfully-deleted'),
            })
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            /* empty */
        }
    }
</script>

<template>
    <AppForm
        ref="formRef"
        v-slot="formProps"
        :rules="dynamicFormRules"
        :values="dynamicFormValues"
    >
        <ChatConversationSidebarInfoClientDetailsFormField
            v-for="(_, name) in dynamicFormValuesEmail"
            :key="name"
            v-model="dynamicFormValues[name]"
            v-model:error="formProps.errors[name]"
            type="email"
            :name="name"
            :placeholder="$t('enter-email-address')"
            :show-status="recentlyUpdated === name"
            :loading="pendingArray.includes(name)"
            @update:model-value="updateContact(name, formProps)"
            @blur.capture="onFieldBlur(name, formProps)"
        >
            <template #icon="{ attrs }">
                <AppIconEmailOutline v-bind="attrs" />
            </template>
        </ChatConversationSidebarInfoClientDetailsFormField>

        <ChatConversationSidebarInfoClientDetailsFormField
            key="new-email"
            v-model="formProps.values.newEmail"
            v-model:error="formProps.errors.newEmail"
            type="email"
            name="newEmail"
            :placeholder="$t('enter-email-address')"
            :loading="pendingArray.includes('newEmail')"
            @update:model-value="createContact('newEmail', formProps)"
            @blur.capture="onFieldBlur('newEmail', formProps)"
        >
            <template #icon="{ attrs }">
                <AppIconEmailPlusOutline v-bind="attrs" />
            </template>
        </ChatConversationSidebarInfoClientDetailsFormField>

        <ChatConversationSidebarInfoClientDetailsFormField
            v-for="(_, name) in dynamicFormValuesPhone"
            :key="name"
            v-model="dynamicFormValues[name]"
            v-model:error="formProps.errors[name]"
            :name="name"
            :placeholder="$t('enter-phone-number')"
            :show-status="recentlyUpdated === name"
            :loading="pendingArray.includes(name)"
            @update:model-value="updateContact(name, formProps)"
            @blur.capture="onFieldBlur(name, formProps)"
        >
            <template #icon="{ attrs }">
                <AppIconPhone v-bind="attrs" />
            </template>
        </ChatConversationSidebarInfoClientDetailsFormField>

        <ChatConversationSidebarInfoClientDetailsFormField
            key="new-phone"
            v-model="formProps.values.newPhone"
            v-model:error="formProps.errors.newPhone"
            name="newPhone"
            :placeholder="$t('enter-phone-number')"
            :loading="pendingArray.includes('newPhone')"
            @update:model-value="createContact('newPhone', formProps)"
            @blur.capture="onFieldBlur('newPhone', formProps)"
        >
            <template #icon="{ attrs }">
                <AppIconPhonePlus v-bind="attrs" />
            </template>
        </ChatConversationSidebarInfoClientDetailsFormField>
    </AppForm>
</template>
