<script
    lang="ts"
    setup
>
    type Props = {
        showSidebar: boolean
    }

    type Emit = {
        (event: 'update:showSidebar', value: boolean): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const sidebarRef = ref<HTMLDivElement>()

    const showSidebar = useSyncProp(props, 'showSidebar', emit)

    const { maxTablet, tablet, maxDesktop } = useWindowSize()

    onMounted(() => {
        onClickOutside(sidebarRef, () => {
            if (tablet.value && maxDesktop.value) {
                showSidebar.value = false
            }
        }, {
            ignore: [
                document.querySelector('#chat-conversation-header__sidebar-trigger'),
            ],
        })
    })
</script>

<template>
    <div
        ref="sidebarRef"
        class="
            max-desktop:z-[10]
            max-desktop:fixed
            max-desktop:top-0
            max-tablet:left-0
            tablet:max-desktop:right-0
            flex
            flex-col
            max-w-[350px]
            min-w-[350px]
            max-tablet:max-w-none
            max-tablet:min-w-full
            max-tablet:w-full
            max-tablet:h-[calc(100%-var(--navbar-height))]
            h-full
            bg-white
            tablet:max-desktop:shadow-lg
        "
    >
        <div class="desktop:hidden flex items-center min-h-[var(--chat-header-height)] p-2">
            <AppButtonIcon
                small
                @click="showSidebar = false"
            >
                <AppIconArrowLeft
                    v-if="maxTablet"
                    key="arrow-left"
                    size="20"
                />

                <AppIconArrowRight
                    v-else
                    key="arrow-right"
                    size="20"
                />
            </AppButtonIcon>
        </div>

        <div class="overflow-auto">
            <ChatConversationSidebarInfo />

            <ChatConversationSidebarMark />
        </div>
    </div>
</template>
