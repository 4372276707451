<script
    lang="ts"
    setup
>
    import type { ChatGif } from '~/ts/types/chat'
    import { useChatStore } from '~/stores/chat'

    type Emit = {
        (event: 'select-gif', value: ChatGif): void
    }

    const emit = defineEmits<Emit>()

    const dropdownRef = ref<ReturnType<typeof defineComponent>>()
    const searchRef = ref<ReturnType<typeof defineComponent>>()

    const search = ref<string>('')

    onStartTyping(() => {
        if (searchRef.value && !searchRef.value.$refs.inputRef.disabled) {
            searchRef.value.$refs.inputRef.focus()
        }
    })

    const searching = ref<boolean>(false)

    const chatStore = useChatStore()

    const gifs = ref<ChatGif[]>([])

    const searchGigs = debounceFn(async () => {
        if (!search.value) {
            gifs.value = chatStore.gifs

            return
        }

        searching.value = true

        try {
            gifs.value = await useApi().chat.gifSearch({ search: search.value })
        } finally {
            searching.value = false
        }
    }, 1000)

    watch(search, () => searchGigs())

    const onOpened = (): void => {
        if (searchRef.value) {
            searchRef.value.$refs.inputRef.focus()
        }
    }

    onMounted(() => {
        chatStore.fillGifs()
            .then(() => {
                gifs.value = chatStore.gifs
            })
    })
</script>

<template>
    <AppDropdown
        ref="dropdownRef"
        from-bottom
        content-class="
            max-h-[50dvh]
            min-w-[100vw]
            tablet:min-w-[432px]
            max-tablet:bottom-full
            max-tablet:border-b
            max-tablet:border-b-[#f6f7f8]
            max-tablet:rounded-[16px_16px_0_0]
            max-tablet:shadow-[0_-16px_30px_0_rgba(46,56,66,0.05)]
        "
        class="max-tablet:static"
        without-padding
        @opened="onOpened()"
    >
        <template #activator="{ toggle, active }">
            <AppButtonIcon
                v-slot="{ color }"
                small
                :active="active"
                class="!mr-2"
                @click="toggle()"
            >
                <AppIconGif
                    size="20"
                    :color="color"
                />
            </AppButtonIcon>
        </template>

        <template #default="{ close }">
            <div class="flex flex-col p-0 tablet:p-4 max-tablet:pt-4">
                <div class="flex items-center gap-2">
                    <AppFormFieldSearch
                        ref="searchRef"
                        v-model.trim="search"
                        name="search"
                        :placeholder="$t('search')"
                        class="!w-auto flex-1 max-tablet:ml-4"
                    />

                    <AppButtonIcon
                        class="!flex tablet:!hidden mr-2"
                        small
                        @click="close()"
                    >
                        <AppIconClose size="20" />
                    </AppButtonIcon>
                </div>

                <ChatConversationFooterInputControlsGifPickerTags v-model:search="search" />

                <div
                    v-if="searching"
                    key="loading"
                    class="flex flex-col items-center justify-center h-[280px]"
                >
                    <AppIconLoading
                        size="34"
                        :animate="true"
                    />

                    <AppHint class="mt-2">
                        {{ $t('loading') }}
                    </AppHint>
                </div>

                <div
                    v-else-if="gifs.length"
                    key="filled"
                    class="overflow-y-auto flex flex-wrap gap-0.5 h-[280px]"
                >
                    <div
                        v-for="gif in gifs"
                        :key="gif.url"
                        class="relative overflow-hidden flex flex-1 min-w-[100px] h-[112px] bg-white cursor-pointer"
                        @click="emit('select-gif', gif); close()"
                    >
                        <img
                            :src="gif.url"
                            alt="Gif"
                            draggable="false"
                            class="absolute w-full h-full left-0 top-0 object-cover"
                        >
                    </div>
                </div>

                <div
                    v-else
                    key="empty"
                    class="flex flex-col items-center justify-center h-[280px]"
                >
                    <AppIconSearch size="34" />

                    <AppHint class="mt-2">
                        {{ $t('nothing-found') }}
                    </AppHint>
                </div>
            </div>
        </template>
    </AppDropdown>
</template>
