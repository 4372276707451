<script
    lang="ts"
    setup
>
    type Props = {
        big?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        big: false,
    })
</script>

<template>
    <span
        :class="[
            'leading-[120%] font-medium',
            { 'text-[60px]': props.big },
        ]"
    >
        <slot />
    </span>
</template>
