<script
    lang="ts"
    setup
>
    import type { ChatBusEventUploadPhotoPayload } from '~/ts/types/chat'

    const { onDesktopChange } = useWindowSize()

    const showSidebar = ref<boolean>(false)
    const sidebarTransitionName = ref<string | undefined>()

    onDesktopChange(v => (showSidebar.value = v))

    onMounted(async () => {
        await nextTick()

        sidebarTransitionName.value = 'slide-x-right-transition'
    })

    const onPaste = (event): void => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items
        const file = items[0].getAsFile()

        if (!file || !fileUtil.isImage(file)) {
            return
        }

        // TODO додати передачу тексту (коли фокусу у полі немає)

        const eventValue: ChatBusEventUploadPhotoPayload = {
            file,
            onValidated: () => event.preventDefault(),
        }

        useEventBus().emit(BUS_EVENT_CHAT_INPUT_UPLOAD_PHOTO, eventValue)
    }

    const onDropped = (file: File): void => {
        if (!file || !fileUtil.isImage(file)) {
            return
        }

        const eventValue: ChatBusEventUploadPhotoPayload = { file }

        useEventBus().emit(BUS_EVENT_CHAT_INPUT_UPLOAD_PHOTO, eventValue)
    }
</script>

<template>
    <AppImageDropZone
        class="relative flex flex-col flex-1"
        @dropped="onDropped"
        @paste="onPaste"
    >
        <ChatConversationHeader v-model:show-sidebar="showSidebar" />

        <ChatConversationBody />

        <ChatConversationFooter />
    </AppImageDropZone>

    <Transition
        :name="sidebarTransitionName"
        mode="out-in"
    >
        <ChatConversationSidebar
            v-show="showSidebar"
            v-model:show-sidebar="showSidebar"
        />
    </Transition>
</template>
