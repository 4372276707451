<script
    lang="ts"
    setup
>
    import type { ChatMessage, ChatMessageConfig } from '~/ts/types/chat'
    import { useChatStore } from '~/stores/chat'

    type Props = {
        message: ChatMessage
        messageConfig: ChatMessageConfig
    }

    type Emit = {
        (event: 'set-show-menu', value: boolean): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const chatStore = useChatStore()

    const currentChat = chatStore.currentChat
</script>

<template>
    <ChatConversationBodyMessageHead
        v-if="props.messageConfig.in && props.messageConfig.firstInGroup"
        key="head"
        :message="props.message"
        :message-config="props.messageConfig"
    />

    <ChatConversationBodyMessageContent
        key="content"
        :message-config="props.messageConfig"
    >
        <ChatConversationBodyMessageContentBubble
            :selected="currentChat.editableMessage?._id === props.message._id"
            :message-config="props.messageConfig"
            @mouseenter.passive="emit('set-show-menu', true)"
            @mouseleave.passive="emit('set-show-menu', false)"
        >
            <div class="text-[14px] leading-[120%]">
                <ChatConversationBodyMessageParsedText :message="props.message" />
            </div>
        </ChatConversationBodyMessageContentBubble>

        <ChatConversationBodyMessageDetails
            :message="props.message"
            :message-config="props.messageConfig"
        />
    </ChatConversationBodyMessageContent>
</template>
