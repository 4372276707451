<script
    lang="ts"
    setup
>
    import type { ChatMessageConfig } from '~/ts/types/chat'

    type Props = {
        messageConfig: ChatMessageConfig
    }

    const props = defineProps<Props>()

    const contentClass: string = (() => {
        let classes = 'flex items-end'

        if (props.messageConfig.out) {
            classes += ' flex-row-reverse'
        }

        return classes
    })()
</script>

<template>
    <div :class="contentClass">
        <slot />
    </div>
</template>
