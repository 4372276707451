import type { Chat } from '~/ts/types/chat'
import type { Operator } from '~/ts/types/operator'
import { ChatStatusEnum } from '~/ts/enums/chat'
import isOwnMessage from '~/helpers/chat/isOwnMessage'
import isMessageReadable from '~/helpers/chat/isMessageReadable'

export const MESSAGE_READER_DEBOUNCE_FOR_READING = 300

export class MessagesReader {
    private timer: ReturnType<typeof setTimeout>

    constructor(
        private readonly currentOperator: Operator,
        private readonly afterRead?: (count: number) => void,
    ) {
    }

    private reedLocalMessages(chat: Chat, lastReadId: string): void {
        const viewedAt = dateUtil.local().toUnixInteger()

        for (const message of chat.messages) {
            if (isMessageReadable(message) && !isOwnMessage(message, this.currentOperator)) {
                message.viewed_at = viewedAt
            }

            if (message._id === lastReadId) {
                return
            }
        }
    }

    public read(chat: Chat, viewedMessageId: string): void {
        clearTimeout(this.timer)

        this.timer = setTimeout(async () => {
            if (chat.status !== ChatStatusEnum.Active) {
                return
            }

            try {
                const data = await useApi().chat.chatMessageRead({
                    chatId: chat.id,
                    messageId: viewedMessageId,
                })

                this.reedLocalMessages(chat, data.last_read)

                this.afterRead?.(data.count)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (error) {
                /* empty */
            }
        }, MESSAGE_READER_DEBOUNCE_FOR_READING)
    }
}
