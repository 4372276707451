<script
    lang="ts"
    setup
>
    import type { ChatMessage, ChatMessageConfig } from '~/ts/types/chat'

    type Props = {
        message: ChatMessage
        messageConfig: ChatMessageConfig
    }

    const props = defineProps<Props>()

    const style = useCssModule()

    const gifClass: string[] = (() => {
        const classes = [ style['chat-message__content__bubble__gif'] ]

        if (props.messageConfig.out) {
            classes.push(style['chat-message__content__bubble__gif--out'])
        } else if (props.messageConfig.in) {
            classes.push(style['chat-message__content__bubble__gif--in'])
        }

        return classes
    })()
</script>

<template>
    <ChatConversationBodyMessageHead
        v-if="props.messageConfig.in && props.messageConfig.firstInGroup"
        key="head"
        :message="props.message"
        :message-config="props.messageConfig"
    />

    <ChatConversationBodyMessageContent
        key="content"
        :message-config="props.messageConfig"
    >
        <ChatConversationBodyMessageContentBubble
            with-media
            :message-config="props.messageConfig"
        >
            <div :class="gifClass">
                <img
                    :src="props.message.image_url"
                    alt="Chat message gif"
                    loading="lazy"
                    decoding="async"
                    draggable="false"
                />
            </div>
        </ChatConversationBodyMessageContentBubble>

        <ChatConversationBodyMessageDetails
            :message="props.message"
            :message-config="props.messageConfig"
        />
    </ChatConversationBodyMessageContent>
</template>

<style
    lang="sass"
    module
    scoped
>
    .chat-message__content__bubble__gif
        overflow: hidden
        position: relative
        width: 250px
        height: 166px
        border-radius: var(--br-top-left, 8px) var(--br-top-right, 8px) 8px 8px

        &--in
            --br-top-left: 0

        &--out
            --br-top-right: 0

        > img
            object-fit: cover
            max-width: 100%
            width: 100%
            height: 100%
</style>
