<script
    lang="ts"
    setup
>
    import type { AnyFn } from '~/ts/types/common'
    import type { FormContext } from '~/ts/types/form'
    import { useChatStore } from '~/stores/chat'
    import handleFormError from '~/helpers/handleFormError'
    import rules from '~/helpers/formValidationRules'

    type FormRules = {
        name: AnyFn
        comment: AnyFn
    }

    type FormValues = {
        name: string
        comment: string
    }

    const formRules: FormRules = {
        name: value => rules.ruleChain(
            () => rules.required(value),
            () => rules.between(value, { min: 2, max: 50, trim: true }),
        ),
        comment: value => rules.between(value, { min: 2, max: 200, trim: true }),
    }

    const chatStore = useChatStore()

    const currentChat = chatStore.currentChat

    const formValues = ref<FormValues>({
        name: currentChat.visitor.name || '',
        comment: currentChat.visitor.comment || '',
    })

    const recentlyUpdated = ref<string>('')

    const pendingArray = ref<string[]>([])

    const updateDetail = debounceFn(async (fieldName: string, formProps: FormContext): Promise<void> => {
        if (
            !formProps.validateField(fieldName)
            || (currentChat.visitor[fieldName] === formProps.values[fieldName])
        ) {
            return
        }

        if (!pendingArray.value.includes(fieldName)) {
            pendingArray.value.push(fieldName)
        }

        try {
            const data = await useApi().visitor.update({
                id: currentChat.visitor.id,
                data: { [fieldName]: formProps.values[fieldName] },
            })

            currentChat.visitor[fieldName] = data[fieldName]

            recentlyUpdated.value = fieldName

            setTimeout(() => {
                recentlyUpdated.value = ''
            }, 500)
        } catch (error) {
            handleFormError({ error, setFieldError: formProps.setFieldError })
        } finally {
            pendingArray.value = pendingArray.value.filter(v => v !== fieldName)
        }
    }, 500)
</script>

<template>
    <AppForm
        v-slot="formProps"
        :rules="formRules"
        :values="formValues"
    >
        <ChatConversationSidebarInfoClientDetailsFormField
            v-model="formValues.name"
            v-model:error="formProps.errors.name"
            name="name"
            :placeholder="$t('enter-name')"
            :show-status="recentlyUpdated === 'name'"
            :loading="pendingArray.includes('name')"
            @update:model-value="updateDetail('name', formProps)"
        >
            <template #icon="{ attrs }">
                <AppIconAccountOutline v-bind="attrs" />
            </template>
        </ChatConversationSidebarInfoClientDetailsFormField>

        <ChatConversationSidebarInfoClientDetailsFormField
            v-model="formValues.comment"
            v-model:error="formProps.errors.comment"
            name="comment"
            :placeholder="$t('enter-comment-for-client')"
            :show-status="recentlyUpdated === 'comment'"
            :loading="pendingArray.includes('comment')"
            @update:model-value="updateDetail('comment', formProps)"
        >
            <template #icon="{ attrs }">
                <AppIconNote v-bind="attrs" />
            </template>
        </ChatConversationSidebarInfoClientDetailsFormField>
    </AppForm>

    <ChatConversationSidebarInfoClientDetailsFormContacts />
</template>
