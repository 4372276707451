<template>
    <div class="flex items-center justify-center gap-1.5 w-full min-h-[82px] select-none">
        <div class="text-[14px] text-[#8a8f9e] leading-[130%]">
            Powered by
        </div>

        <div class="text-[14px] text-[#8a8f9e] font-medium leading-[130%]">
            <AppBranding color="#8a8f9e" />
        </div>
    </div>
</template>
