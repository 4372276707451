<script
    lang="ts"
    setup
>
    import type { KBArticle } from '~/ts/types/knowledge-base'
    import { useChatStore } from '~/stores/chat'
    import findItem from '~/helpers/knowledge-base/findItem'

    type Emit = {
        (event: 'select-article', value: KBArticle): void
    }

    const emit = defineEmits<Emit>()

    const chatStore = useChatStore()

    const selectArticle = (id: KBArticle['_id']): void => {
        emit('select-article', findItem(chatStore.knowledgeBaseItems, id) as KBArticle)
    }

    onMounted(() => {
        nextTick(() => chatStore.fillKnowledgeBase())
    })
</script>

<template>
    <AppFormFieldSelectKB
        with-close-trigger
        :items="chatStore.knowledgeBaseItems"
        :dropdown-args="{
            class: 'max-tablet:static',
            contentClass: `
                max-h-[50dvh]
                min-w-[100vw]
                tablet:min-w-[520px]
                desktop:min-w-[624px]
                max-tablet:bottom-full
                max-tablet:border-b
                max-tablet:border-b-[#f6f7f8]
                max-tablet:rounded-[16px_16px_0_0]
                max-tablet:shadow-[0_-16px_30px_0_rgba(46,56,66,0.05)]
            `,
        }"
        @update:model-value="selectArticle"
    />
</template>
