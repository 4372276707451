<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                d="M12 3C13.1935 3 14.3381 3.47411 15.182 4.31802C16.0259 5.16193 16.5 6.30653 16.5 7.5C16.5 8.69347 16.0259 9.83807 15.182 10.682C14.3381 11.5259 13.1935 12 12 12C10.8065 12 9.66193 11.5259 8.81802 10.682C7.97411 9.83807 7.5 8.69347 7.5 7.5C7.5 6.30653 7.97411 5.16193 8.81802 4.31802C9.66193 3.47411 10.8065 3 12 3V3ZM12 5.25C11.4033 5.25 10.831 5.48705 10.409 5.90901C9.98705 6.33097 9.75 6.90326 9.75 7.5C9.75 8.09674 9.98705 8.66903 10.409 9.09099C10.831 9.51295 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.51295 13.591 9.09099C14.0129 8.66903 14.25 8.09674 14.25 7.5C14.25 6.90326 14.0129 6.33097 13.591 5.90901C13.169 5.48705 12.5967 5.25 12 5.25V5.25ZM12 13.125C15.0038 13.125 21 14.6212 21 17.625V21H3V17.625C3 14.6212 8.99625 13.125 12 13.125ZM12 15.2625C8.65875 15.2625 5.1375 16.905 5.1375 17.625V18.8625H18.8625V17.625C18.8625 16.905 15.3413 15.2625 12 15.2625Z"
                :fill="color"
            />
            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>
