<script
    lang="ts"
    setup
>
    type Props = {
        value: string
    }

    const props = defineProps<Props>()

    const updatedTimes = ref<number>(0)

    const rawNotSeconds = computed<number | undefined>(() => {
        // Для тригера
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        updatedTimes.value

        return dateUtil.rawNow().toSeconds()
    })

    const time = useFormatDate(rawNotSeconds, {
        format: DATE_DISPLAY_TIME_FORMAT,
        timezoneForFormat: props.value,
    })

    useTimer(
        () => updatedTimes.value++,
        1000 * 60,
        {
            immediate: true,
            interval: true,
        },
    )
</script>

<template>
    {{ time }} ({{ props.value }})
</template>
