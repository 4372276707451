import type { AnyFn } from '~/ts/types/common'
import type { ChatMessage, ChatMessageConfig } from '~/ts/types/chat'
import isMessageReadable from '~/helpers/chat/isMessageReadable'

export type MessagesObserverMessageContext = {
    message: ChatMessage
    messageConfig: ChatMessageConfig
}

export class MessagesObserver {
    private observer: IntersectionObserver
    private deferredQueue: { el: Element, getContext: () => MessagesObserverMessageContext }[] = []
    private entryCallbacks: Map<Element, () => MessagesObserverMessageContext> = new Map()
    private lastMessageIdToRead = ''

    constructor(
        public readonly container: Ref<HTMLElement>,
        public readonly readMessages: AnyFn,
    ) {
    }

    private processDeferredQueue(): void {
        if (!this.deferredQueue.length) {
            return
        }

        const deferredQueue = this.deferredQueue

        this.deferredQueue = []

        for (const index in deferredQueue) {
            const { el, getContext } = deferredQueue[index]

            this.observe(el, getContext)
        }
    }

    private handleIntersection(entries: IntersectionObserverEntry[]): void {
        let needToRead = false

        for (const { target, isIntersecting } of entries) {
            if (!isIntersecting) {
                continue
            }

            const messageContext: MessagesObserverMessageContext = this.entryCallbacks.get(target)()

            // В процесі відправки
            if (!messageContext.message._id) {
                continue
            }

            if (
                messageContext.messageConfig.in
                && isMessageReadable(messageContext.message)
                && (messageContext.message._id > this.lastMessageIdToRead)
            ) {
                this.lastMessageIdToRead = messageContext.message._id

                needToRead = true
            }
        }

        if (needToRead) {
            this.readMessages(this.lastMessageIdToRead)
        }
    }

    public run(): void {
        if (this.observer) {
            return
        }

        this.observer = new IntersectionObserver(
            entries => this.handleIntersection(entries),
            {
                root: this.container.value,
                rootMargin: '0px',
                threshold: 0.1,
            },
        )

        setTimeout(() => this.processDeferredQueue(), 0)
    }

    public observe(el: Element, getContext: () => MessagesObserverMessageContext): void {
        if (!this.observer) {
            this.deferredQueue.push({ el, getContext })

            return
        }

        if (!el || !getContext) {
            return
        }

        this.entryCallbacks.set(el, getContext)

        this.observer.observe(el)
    }

    public unobserve(el: Element): void {
        if (!this.observer || !el) {
            return
        }

        this.observer.unobserve(el)

        this.entryCallbacks.delete(el)
    }

    public stop(): void {
        this.observer?.disconnect()
    }
}
