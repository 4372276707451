<script
    lang="ts"
    setup
>
    import type { ChatMessageConfig } from '~/ts/types/chat'

    type Props = {
        messageConfig: ChatMessageConfig
        selected?: boolean
        withMedia?: boolean
    }

    const props = defineProps<Props>()

    const contentBubbleClass = computed<string>(() => {
        let classes = `
            whitespace-pre-wrap
            true-word-breaks
            overflow-hidden
            flex
            items-center
            max-w-[396px]
            min-h-[36px]
            py-2.5
            px-4
        `

        let bg = ''
        let text = ''

        if (props.messageConfig.isOperator) {
            bg = 'bg-[#ebeef1]'
            text = 'text-black'
        } else {
            bg = 'bg-[#30353c]'
            text = 'text-white'
        }

        if (props.messageConfig.out) {
            classes += ' ml-2 rounded-[8px_0_8px_8px]'
        } else if (props.messageConfig.in) {
            classes += ' mr-2 bg-[#30353c] rounded-[0_8px_8px_8px]'
        }

        if (props.messageConfig.isNote) {
            bg = 'bg-[#fefae8]'
            text = 'text-black'

            classes += ' border border-[#eee5bc]'
        }

        if (props.selected) {
            bg = 'bg-[#b5bccf]'
        }

        if (props.withMedia) {
            classes += ' flex-col w-[min(250px,100%)] !p-0'
        }

        classes += ` ${ bg } ${ text }`

        return classes
    })
</script>

<template>
    <div :class="contentBubbleClass">
        <slot />
    </div>
</template>
