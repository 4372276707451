<script
    lang="ts"
    setup
>
    import type { ChatMessageDateGroup } from '~/ts/types/chat'

    type Props = {
        container: HTMLElement
        dateGroup: ChatMessageDateGroup
        isContainerScrolling: boolean | unknown
    }

    const props = defineProps<Props>()

    const stickyDateRef = ref<ReturnType<typeof defineComponent>>()

    const isPinned = ref<boolean>(false)

    const isInvisible = computed<boolean>(() => {
        if (
            !props.container
            || !stickyDateRef.value
            || props.isContainerScrolling
        ) {
            return false
        }

        const { offsetTop: elOffsetTop } = stickyDateRef.value.$el

        const top = Math.floor(elOffsetTop - props.container.scrollTop)

        return (top <= 0) || top >= props.container.clientHeight
    })

    const containerClass = computed<string>(() => {
        let classes = `
            z-[1]
            sticky
            -top-px
            mt-px
            origin-[center_top]
            transition-[opacity,transform]
        `

        if (isInvisible.value) {
            classes += ' opacity-0 scale-y-0'
        }

        return classes
    })

    const formattedDate = useFormatDate(props.dateGroup.datetime, {
        format: null,
        relativeCalendar: true,
        autoUpdate: true,
        autoUpdateMs: 1000 * 60 * 5,
    })

    let observer: IntersectionObserver | undefined

    onMounted(() => {
        observer = new IntersectionObserver(
            ([ entry ]) => (isPinned.value = entry.intersectionRatio < 1),
            { threshold: [ 1 ] },
        )

        observer.observe(stickyDateRef.value.$el)
    })

    onBeforeUnmount(() => {
        observer?.disconnect()

        observer = undefined
    })
</script>

<template>
    <AppDividerWithText
        ref="stickyDateRef"
        :hide-lines="isPinned"
        :class="containerClass"
    >
        <div class="whitespace-nowrap py-1 px-2 text-[14px] leading-[120%] bg-[#f6f7f8] rounded-[20px]">
            {{ formattedDate }}
        </div>
    </AppDividerWithText>
</template>
