<script
    lang="ts"
    setup
>
    import type { ChatBusEventUploadPhotoPayload, ChatGif } from '~/ts/types/chat'
    import type { KBArticle } from '~/ts/types/knowledge-base'
    import { ChatMessageTypeEnum } from '~/ts/enums/chat'
    import { useChatStore } from '~/stores/chat'
    import { useUserStore } from '~/stores/user'

    type Props = {
        inputModel: string
        isNote: boolean
    }

    type Emit = {
        (event: 'insert-into-input', value: string, replaceValue?: boolean): void
        (event: 'focus-input'): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const appConfig = useAppConfig()

    const chatStore = useChatStore()
    const userStore = useUserStore()

    const imageInputAccept = computed<string>(() => appConfig.supportedImageTypesToUpload.join(','))

    const onSelectGif = (gif: ChatGif) => chatStore.sendMessage(ChatMessageTypeEnum.Gif, { gif }, props)

    const onSelectArticle = (article: KBArticle) => {
        return chatStore.sendMessage(ChatMessageTypeEnum.Article, { article }, props)
    }

    const onSelectImage = (event) => {
        const { files } = event.target

        if (!files || !files[0]) {
            return
        }

        const eventValue: ChatBusEventUploadPhotoPayload = { file: files[0] }

        useEventBus().emit(BUS_EVENT_CHAT_INPUT_UPLOAD_PHOTO, eventValue)
    }
</script>

<template>
    <div class="flex items-center">
        <ChatConversationFooterInputControlsQuickAnswers
            :input-model="props.inputModel"
            @select-quick-answer="emit('insert-into-input', $event, true)"
            @closed="emit('focus-input')"
        />

        <ChatConversationFooterInputControlsKnowledgeBase
            @select-article="onSelectArticle"
            @closed="emit('focus-input')"
        />

        <ChatUIEmojiPicker
            @select-emoji="emit('insert-into-input', $event)"
            @opened="emit('focus-input')"
            @closed="emit('focus-input')"
        />

        <ChatConversationFooterInputControlsGifPicker
            @select-gif="onSelectGif"
            @closed="emit('focus-input')"
        />

        <AppTooltipProRestriction
            :tooltip-args="{
                offsetX: 110,
                tailOffsetX: 110,
                disabled: !!userStore.activeSubscription,
            }"
        >
            <template #activator="{ open, close }">
                <AppButtonIcon
                    small
                    :class="{ '!cursor-default': !userStore.activeSubscription }"
                    @mouseenter.passive="open"
                    @mouseleave.passive="close"
                    @click="userStore.activeSubscription ? $refs.fileRef.click() : open()"
                >
                    <input
                        key="input"
                        ref="fileRef"
                        type="file"
                        :accept="imageInputAccept"
                        :disabled="!userStore.activeSubscription"
                        class="!hidden"
                        @change="onSelectImage($event); $refs.fileRef.value = null"
                    />

                    <AppIconAttachment
                        v-if="userStore.activeSubscription"
                        key="active"
                        size="20"
                    />

                    <AppIconAttachmentLocked
                        v-else
                        key="locked"
                        size="20"
                    />
                </AppButtonIcon>
            </template>
        </AppTooltipProRestriction>
    </div>
</template>
